/* Landing section styles */
:root {
  --bgImageUrl: url("");
}

#landing_section {
  min-width: 360px !important;
  /* overflow: hidden; */
  position: relative;
  /* z-index: 1; */
}

/* .text-shadow {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); 
} */

#landing_section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--bgImageUrl);
  background-size: cover;
  background-position: center;
  background-color: #0D0D0D;
  opacity: 0.3;
}

#landing-bottom-centered-image {
  width: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
  height: 5.8rem;
}

#banner_section {
  height: 52.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing_title_section {
  text-align: center;
}

.landing_title {
  color: var(--pure-white-color, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.landing_subtitle {
  margin-top: 1rem;
  color: var(--pure-white-color, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.launching_in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
}

#banner_section {
  position: relative;
}

.image-container {
  position: relative;
}


.launch_timer {
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-size: 32px !important;
  font-weight: 600;
  line-height: 36px;
}

.launch_text{
font: normal normal 600 14px/19.6px Poppins;
}
.app_site_name{
  color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(0deg, #DB2349 -13.28%, #EA1855 110.94%);
}

.separator {
  margin: 0 5px;
}

.landing_labels {
  display: flex;
  justify-content: space-evenly;
}

.landing_label {
  font-size: 14px;
  width: 2.45em;
  min-width: 1.45em !important;
}

.launch_timer_width{
  min-width:1.45em !important;
}

.landing_labels{
  margin-left: -2px !important;
}
@media screen and (max-width: 1080px) {
  #landing_section .launch_timer_coming_soon{
    font-size: 110% !important;
  }

  #landing-bottom-centered-image {
    width: 25%;
  }
}

@media screen and (max-width: 1399px) {

  .landing_title {
    font-size: 2.4rem !important;
  }

  .landing_subtitle {
    font-size: 1.2rem !important;
  }

}

@media screen and (max-width: 900px) {
  #landing-bottom-centered-image {
    width: 26%;
  }
}

@media screen and (max-width: 850px) {
  #landing-bottom-centered-image {
    width: 29%;
  }
}

@media screen and (max-width: 767px) {
  #landing-bottom-centered-image {
    width: 35% !important;
  }

  #banner_section {
    height: 30rem !important;
  }

  .landing_title {
    font-size: 1.6rem !important;
  }

  .landing_subtitle {
    font-size: 1rem !important;
  }

}

@media screen and (max-width: 850px) {
  #landing-bottom-centered-image {
    width: 30%;
  }
}

@media screen and (max-width: 575px) {

  #landing-bottom-centered-image {
    height: 5.9rem !important;
    width: 43% !important;
  }

  .landing_title {
    font-size: 1.4rem !important;
  }

  .launch_timer {
    font-size: 1.5rem !important;
  }
  #landing_section .launch_timer_coming_soon{
    font-size: 1rem !important;
  }
  #landing_section .landing-bottom-centered-image_coming{
    height: 3.8rem!important;
  }

  .landing_subtitle {
    font-size: 0.99rem !important;
  }
}

@media screen and (max-width: 510px) {
  .landing_title {
    font-size: 1.3rem !important;
  }

  .landing_subtitle {
    font-size: 0.9rem !important;
  }

}

@media screen and (max-width: 430px) {
  #landing_section::before {
      background-image: none;
      background-size: auto;
      background-position: initial;
  }
  #lading_section_for_mobile_web::before {
    background-image: var(--bgImageUrl);
    background-size: cover;
    background-position: center; 
    margin-top:4.2rem !important;
    height: 15.675rem !important;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block !important;
    opacity: 0.5; 
  }
  #banner_section {
    height: 18rem !important;
    opacity: 1 !important;
  }
  .launch_timer {
    margin-top: -14px !important;
  }
  .launch_timer {
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    line-height: 1.01rem !important;
  }
  .landing_title_section {
    margin-top: 6rem !important;
  }
  .landing_title {
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }
  .landing_subtitle {
    margin-top: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    line-height: 1.4rem !important;
  }
  #landing-bottom-centered-image {
    height: 3.4rem !important;
    width: 45% !important;
  }

  #lading_section_for_mobile_web::before {
    height: 17.6rem !important;
  }

  #landing_section .lading_section_for_mobile_web_coming::before{
    height: 17.2rem !important;
  }
  .launch_timer_coming_soon{
    padding-top: 2em;
  }
/* new */
  #landing_section .landing-bottom-centered-image_coming {
    height: 1.9rem!important;
    width: 38%!important;
  }
  .launch_text{
    font-size: 9px !important;
    line-height: 48px !important;
  }
  .launch_timer{
    line-height: 0.9rem !important;
  }
  .landing_label{
    font-size: 8px !important;
  }
  .launching_in{
    margin-top: -3px !important;
  }
  .landing_labels{
    margin-left: 0px !important;
  }

}

@media screen and (max-width: 381px) {
  .landing_title {
    font-size: 1.1rem !important;
  }
  .landing_subtitle {
    font-size: 0.8rem !important;
  }
  #feature_section {
    position: relative;
    top: -1px;  
  }
}

@media screen and (max-width: 359px) {
  #banner_section {
    height: 17.9064rem !important;
  }
  #feature_section {
    position: relative;
    top: -2px;  
  }
}

@media screen and (min-width: 1200px) {
  #feature_section {
    position: relative;
    top: -3px;  
  }
}
/* round button styles*/
.touch_btn_div {
  top: 96.9%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.rounded_pink_btn {
  position: relative;
  width: max-content;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1.875rem;
  background: linear-gradient(0deg, #9D0154 -13.28%, #EA1855 110.94%);
  color: rgba(255, 255, 255, 1);
  font: 600 24px/140% "Poppins";
  border: none !important;
  gap: 1rem;
  
}

.launch_text_live{
  line-height: 95px;
}

.launched_div_styles{
  top: 26% !important;
}

.landing-bottom-centered-image{
  width: auto !important;
  height: 4.8rem !important;
}

.image-container{
  z-index: 1;
}


@media screen and (max-width: 767px) {
  .rounded_pink_btn {
    font-size: 1.2rem !important;
  }
  #landing_section .landing-bottom-centered-image {
    width: 50% !important;
  }
}

@media screen and (max-width: 597px) {
  #landing_section .landing-bottom-centered-image {
    width: 50% !important;
    height: 4.9rem !important;
  }
}

@media screen and (max-width: 499px) {
  .rounded_pink_btn {
    font-size: 1rem !important;
  }
  #landing_section .landing-bottom-centered-image {
    width: 49% !important;
    height: 4.3rem !important;
  }
}

@media screen and (max-width: 430px) {
  .rounded_pink_btn {
    height: 35px !important;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1.9531rem;
    font: 600 12px/15px "Poppins" !important;
    border: none !important;
    gap:5px;
  }
  .launch_text_live {
    font-size: 9px !important;
    line-height: 68px !important;
  }
#landing_section .lading_section_for_mobile_web::before {
  height: 16.8rem !important;
}
#landing_section .landing-bottom-centered-image {
  width: 50% !important;
  height: 3rem !important;
} 
}

