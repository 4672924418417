/* Gallery section styles */
#gallery_section {
    background: radial-gradient(47.4% 47.4% at 50% 51.71%, #27214B 0%, #170C24 100%);
}

.aurm_actions_container {
    padding: 6.5rem 0;
}

.aurm_actions_heading {
    font: 700 3rem/57.6px "Poppins";
    letter-spacing: 0;
    padding-bottom: 80px !important;
}

.aurm_actions_action_text_color {
    background: #DB2349;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.aurm_actions_cards {
    /* border-radius: 8px !important; */
    border: none;
    background: none;
    position: relative;
    overflow: hidden;
}

.aurm_action_images {
    max-width: 100% !important;
    height: auto;
    border-radius: 8px !important;

}


.aurm_actions_cards_margin {
    margin-bottom: 32px;
    margin-top: 32px;
}

.aurm_action_image_translucent{
    opacity: 0.6;
}

.gallery_video_play_btn{
    height: 15.6%;
    width: 15.6%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* This places the video play button on top of the background image */
}

.gallery_become_member_btn{
    margin-top: 5rem;
    width: 20.63rem;
    height: 4rem;
    padding: 12px, 18px, 12px, 18px;
    border-radius: 3.75rem;
    background: linear-gradient(0deg, #9D0154 -13.28%, #EA1855 110.94%);
    color: var(--color-white, #fff);
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 34px;
    border: none;
}

.gallery_become_member_button_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .aurm_actions_heading {
        font-size: 2.3rem !important;
        padding-bottom: 42px !important;
    }
}

@media screen and (max-width: 506px) {
    .aurm_actions_heading {
        font-size: 1.8rem !important;
        padding-bottom: 28px !important;
    }
}

@media screen and (max-width: 430px) {
    .aurm_actions_heading {
        font-size: 1.5rem !important;
        line-height: 28.8px !important;
        padding-bottom: 12px !important;

    }
    .aurm_actions_cards_margin {
        margin-bottom: 12px;
        margin-top: 12px;

    }
    .gallery_become_member_btn{
        margin-top: 23.97px;
        width: 206px;
        height: 40px;
        padding: 7.51px 11.27px 7.51px 11.27px;
        border-radius: 37.56px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        border: none;
    }

    .aurm_actions_container {
        padding: 33.6px 19.2px 33.6px 19.2px !important;
    }

}

@media screen and (max-width: 396px) {
    .aurm_actions_heading {
        font-size: 1.35rem !important;
    }
}